import React from 'react'
import { graphql } from 'gatsby'

// Libraries
import { find as _find } from 'lodash'

// Components
import {
  TitleDefault,
  TitleAlt,
  BannerDefault,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import GravityForm from 'components/GravityForm'

// Styles
import 'styles/templates/WhitepaperTemplate.scss'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        title: banner_title
        subtitle: banner_subtitle
        description: banner_description

        fileId: file_download
      }
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
    }
    media: allWordpressWpMedia {
      edges {
        node {
          id: wordpress_id
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

const Page = ({
  location,
  data: {
    page: {
      path,
      acf: { backgroundImage, title, subtitle, description, fileId },
      wpml_current_locale,
      yoast_meta,
    },
    media,
  },
}) => {
  const {
    node: {
      localFile: { publicURL },
    },
  } = _find(media.edges, ({ node: { id } }) => id === fileId)

  return (
    <Layout backgroundImage={backgroundImage}>
      {backgroundImage && backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: yoast_meta }}
          pathname={path}
          image={backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: yoast_meta }} pathname={path} />
      )}
      <div className="demo-template">
        <BannerDefault className="py-5">
          <div className="py-4">
            <TitleAlt>{subtitle}</TitleAlt>
            <TitleDefault asH1 className="pl-lg-5">
              {title}
            </TitleDefault>
            <ContentDefault className="mt-4">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </ContentDefault>
          </div>
        </BannerDefault>

        <div className="container pb-5">
          <BoxDefault>
            <ContentDefault className="py-4 px-3 px-lg-5">
              <GravityForm
                id={wpml_current_locale === 'nl_NL' ? 5 : 6}
                defaultValues={{
                  input_4: location.host + publicURL,
                }}
              />
            </ContentDefault>
          </BoxDefault>
        </div>
      </div>
    </Layout>
  )
}

export default Page
